import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from "react-data-table-component";
import axios from 'axios';
import FileUpload from './FileUpload';

import { Auth } from 'aws-amplify';
import { refreshToken } from './auth.js';
import LoginPage from "./LoginPage";
import { auth } from './auth.js';
import configData from './config.json';
import { init } from './init';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';


library.add(faSquarePlus);

function Admin() {

  const [isLoading, setIsLoading] = useState(false);

  // check login  
  const [isAuthenticated, setIsAuthenticated] = useState(auth.getAuth());

  function handleLogin() {
    setIsAuthenticated(true);
    setRefreshData(true);
  }
  function handleLogout() {
    setIsAuthenticated(false);
  }

  useEffect(() => {
    // call init function with setIsAuthenticated prop
    init(setIsAuthenticated);
  }, []);

  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const [apiData, setApiData] = useState([]);

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    const result = apiData.filter(row =>
      row.name.toLowerCase().includes(value) ||
      row.collection.toLowerCase().includes(value) ||
      row.description.toLowerCase().includes(value)
    );
    setFilteredData(result);
    setSearchText(value);
  };



  // const filteredData = data.filter((item) =>
  //   item.name.toLowerCase().includes(filterText.toLowerCase()) ||
  //   item.city.toLowerCase().includes(filterText.toLowerCase()) ||
  //   item.country.toLowerCase().includes(filterText.toLowerCase())
  // );


  const [showModal, setShowModal] = useState(false);
  const [input1Value, setInput1Value] = useState('');
  const [input2Value, setInput2Value] = useState('');

  const [shouldPost, setShouldPost] = useState(false);

  


  const handleClick = (input1, input2, e, post) => {
    e.stopPropagation();
    setInput1Value(input1);
    setInput2Value(input2);
    setIsLoading(true)
    setShowModal(true);
    setShouldPost(post);
  };

  

  

  const columns = [
    {
      name: 'Collection',
      selector: (row) => row.collection,
      sortable: true,
      textFilter: true
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      textFilter: true
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true
    },
    {
      name: 'Price',
      selector: (row) => row.price,
      sortable: true

    },
    {
      name: 'Number',
      selector: (row) => row.number,
      sortable: true
    }
    ,
    {
      name: 'Rating',
      selector: (row) => row.rating,
      sortable: true
    }
    ,
    {
      name: 'Date Added',
      selector: (row) => row.dateadded,
      sortable: true
    }
    ,
    {
      name: 'Height',
      selector: (row) => row.height,
      sortable: true
    }
    ,
    {
      name: "Actions",
      cell: (row) => (
        <Button onClick={(e) => handleClick(row.name, row.collection, e, true)} variant="primary">Edit</Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  // openModal = row => {
  //   // Open modal here
  // }





  useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken');
      const geturl = "https://" + configData.apigatewayid + ".execute-api.eu-west-1.amazonaws.com/prod/scandb";
      axios.post(geturl, {
        'scanType': 'string',
        'atrrName': 'table',
        'attValue': 'collectablesItems'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        },
      })
        .then(response => {
          try {
            var jsonObj = JSON.parse(response.data);
  
          }
          catch (error) {
            console.log('error1');
            var jsonObj = response.data;
          }
          var newObj = [];
          for (let obj in jsonObj) {
            jsonObj[obj]['id'] = jsonObj[obj]['collection'] + '/' + jsonObj[obj]['name'];
            newObj.push(jsonObj[obj]);
          }
          setData(newObj);
          setFilteredData(newObj); // initialize filteredData with the same data
          setApiData(newObj);
        })
        .catch(error => {
          refreshToken();
          console.error(error);
        });
   
  }, [refreshData]);

  useEffect(() => {
    setRefreshData(false);
  }, [data]);
  

  const customStyles = {
    headRow: {
      fontWeight: 'bold',
      backgroundColor: '#F2F2F2',
    },
    rows: {
      backgroundColor: '#EFEFEF',
    },
    striped: {
      backgroundColor: '#FFFFFF',
    },
  };

  return (
    <div>
      <h1>Admin page</h1>
      <Container >
        <Row >
          <Col xs={4}>
            {isAuthenticated ? (
              <p>You are already logged in.</p>
            ) : (
              <LoginPage onLogin={handleLogin} />
            )}
            <Button variant="outline-secondary" onClick={handleLogout}>
              Log out
            </Button>
          </Col>
          <Col xs={4}>
            {/* <Button onClick={(e) => handleClick('', '', e, false)} variant="primary"><FontAwesomeIcon icon="square-plus" /></Button> */}
            <Button onClick={(e) => handleClick('', '', e, false)} variant="primary">Add</Button>
            {showModal && (
              <FileUpload
                input1Value={input1Value}
                input2Value={input2Value}
                shouldPost={shouldPost}
                onModalClose={() => {setShowModal(false); setIsLoading(false);  setRefreshData(true);}}
                key={input1Value + input2Value}
                isLoading={isLoading}
              />
            )}
          </Col>
          <Col xs={3}>
            <InputGroup>
              <FormControl
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={handleSearch}
              />
              {/* <InputGroup.Append>
                <Button variant="outline-secondary">Search</Button>
              </InputGroup.Append> */}
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
          {isLoading && (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            <DataTable
              title="Products"
              columns={columns}
              data={filteredData}
              customStyles={customStyles}
              pagination
              highlightOnHover
              striped={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Admin;
