import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Button, Modal, Spinner, InputGroup, FormControl, Form } from 'react-bootstrap';
import BarGraph from './BarGraph';

function Collection() {
  const navigate = useNavigate();

  const { collectionName, collectionId } = useParams();
  const [items, setItems] = useState([]);
  const [maxPrice, setMaxPrice] = useState([]);
  const [next, setNext] = useState([]);
  const [prev, setPrev] = useState([]);

  useEffect(() => {
    const url = "../images/collection_staged.json";
    axios.get(url)
      .then(response => {
        try {
          var jsonObj = JSON.parse(response.data);
        }
        catch (error) {
          console.log('error1');
          var jsonObj = response.data;
        }

        for (let obj in jsonObj) {
          if (jsonObj[obj]['name'] == collectionId && jsonObj[obj]['collection'] == collectionName) {
            setItems(jsonObj[obj]);
          }
        }
        setMaxPrice(Math.max(...jsonObj.map((item) => item.price)));

        jsonObj.sort((a, b) => a.name.localeCompare(b.name));

        var currentItemIndex = null;
        var foundNext = false;
        var foundPrev = false;

        for (let i = 0; i < jsonObj.length; i++) {
          if (jsonObj[i].name === collectionId && jsonObj[i].collection === collectionName) {
            currentItemIndex = i;
            break;
          }
        }

        if (currentItemIndex !== null) {
          const totalItems = jsonObj.length;
          const nextIndex = (currentItemIndex + 1) % totalItems;
          const prevIndex = (currentItemIndex - 1 + totalItems) % totalItems;

          let nextItem = null;
          let prevItem = null;

          for (let i = nextIndex; i !== currentItemIndex; i = (i + 1) % totalItems) {
            if (jsonObj[i].collection === collectionName) {
              nextItem = jsonObj[i];
              foundNext = true;
              break;
            }
          }

          for (let i = prevIndex; i !== currentItemIndex; i = (i - 1 + totalItems) % totalItems) {
            if (jsonObj[i].collection === collectionName) {
              prevItem = jsonObj[i];
              foundPrev = true;
              break;
            }
          }

          if (!foundNext) {
            nextItem = jsonObj[nextIndex];
          }

          if (!foundPrev) {
            prevItem = jsonObj[prevIndex];
          }

          setNext(nextItem);
          setPrev(prevItem);
        }

        setMaxPrice(Math.max(...jsonObj.map(item => item.price)));
      })
      .catch(error => {
        console.log('error2');
        console.log(error);
      });
  }, [collectionName, collectionId]);


  const handleClick = (input1, input2) => {
    navigate('/' + input2 + '/' + input1);
  };


  return (
    <div>
      <div className="top-section">
        <div className="pagination left">
          <button className="pagination-button">
            <span className="pagination-icon">&#8249;</span>
            <span className="pagination-text">
              <img onClick={() => handleClick(prev['name'], prev['collection'])}
                className="image-small"
                src={"https://collectables.ashworthcloud.com/images/collection/" + collectionName + "/" + prev['name'] + ".jpg"}
                alt={collectionName}
              />
            </span>

          </button>
        </div>
        <div className="header">
          <h1 className="header-title">{collectionId}</h1>
        </div>
        <div className="pagination right">
          <button className="pagination-button">
            <span className="pagination-text">
              <img onClick={() => handleClick(next['name'], next['collection'])}
                className="image-small"
                src={"https://collectables.ashworthcloud.com/images/collection/" + collectionName + "/" + next['name'] + ".jpg"}
                alt={collectionName}
              /></span>
            <span className="pagination-icon">&#8250;</span>
          </button>
        </div>
      </div>
      <div className="collection-container">
        <Row >
          <Col md={4}>
            {/* <h1>Collection: {collectionName} Item: {collectionId}</h1> */}
            <div className="exciting-image">
              <div className="diagonal-lines"></div>
              <img
                className="image"
                src={"https://collectables.ashworthcloud.com/images/collection/" + collectionName + "/" + collectionId + ".jpg"}
                alt={collectionName}
              />
            </div>
          </Col>
          <Col md={8}>
            <Row >
              <Col md={12}>
                <p>{items['description']}</p>
              </Col>
            </Row>
            <Row >
              <Col md={6}>
                <BarGraph items={items} maxPrice={maxPrice} />
              </Col>
              <Col md={6}>
                <div className="details-collection">
                  <Row >
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Name:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={items['name']} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Price:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={items['price']} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                  </Row>
                  <Row >
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Number:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={items['number']} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Rating:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={items['rating']} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                  </Row>
                  <Row >
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Height:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={items['height']} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>


          </Col>
        </Row>
        <Row >
          <Col md={6}>

          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Collection;
