import { useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Container, Row, Col, Button, Spinner, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import configData from './config.json';
import{ auth } from './auth.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faUnlock);

Amplify.configure(awsconfig);
// >>New - Configuring Auth Module
Auth.configure(awsconfig);

function LoginPage(props) {
  // for modal
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // for login
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isPasswordExpired, setIsPasswordExpired] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit called');
    setLoading(true);

    try {
      const user = await Auth.signIn(username, password);
      // console.log(user.challengeName);

      setErrorMessage('');
      setUser(user);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsPasswordExpired(true);
        setLoading(false);
      } else {
        setLoading(false);
        console.log(user);
        // handle successful login
        setIsLoggedIn(true);
        auth.setAuth(true);
        props.onLogin();
        setShow(false);

        // const jwtToken = user.signInUserSession.accessToken.jwtToken;
        const jwtToken = user.signInUserSession.idToken.jwtToken;
        // Store the JWT token in local storage or session storage for future use
        localStorage.setItem('jwtToken', jwtToken); // Store the JWT token in localStorage

        const refreshToken = user.signInUserSession.refreshToken.token;
        localStorage.setItem('refreshToken', refreshToken);

        const clientId = user.pool.clientId;
        localStorage.setItem('clientId', clientId);

        const domainName = configData.domainName;
        localStorage.setItem('domainName', domainName);
      }

    } catch (error) {
      setLoading(false);
      console.error(error);
      setErrorMessage('Invalid username or password.');
    }
  }

  async function handleNewPasswordSubmit(event) {
    event.preventDefault();
    console.log('handleNewPasswordSubmit called');
    setLoading(true);

    try {
      await Auth.completeNewPassword(user, newPassword);
      setIsPasswordExpired(false);
      setPassword(newPassword);

    } catch (error) {
      setLoading(false);
      console.error(error);
      setErrorMessage('Unable to update password. Please try again.');
    }
  }



  return (
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Admin Login
      </Button> */}
      {/* <FontAwesomeIcon icon="unlock" onClick={handleShow} /> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Admin Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              {isPasswordExpired ? (
                <Form onSubmit={handleNewPasswordSubmit}>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>New Password:</Form.Label>
                        <Form.Control type="password" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
                      </Form.Group>
                      <Button type="submit" variant="primary">Submit</Button>
                    </Col>
                  </Row>
                </Form>
              ) :
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Username:</Form.Label>
                          <Form.Control type="text" value={username} onChange={(event) => setUsername(event.target.value)} />
                          {errorMessage && <span className="error">{errorMessage}</span>}
                        </Form.Group>
                        <div style={{ marginTop: '1rem' }}>
                          <Button type="submit" variant="primary">Submit</Button>
                        </div>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Password:</Form.Label>
                          <Form.Control type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                      {loading && (
                          <div className="overlay">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
             
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LoginPage;