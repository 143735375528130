import React from "react";
import './BarGraph.css'

const BarGraph = ({ items, maxPrice }) => {
    console.log('maxPrice: ', maxPrice);
    console.log('items: ', items);
    
    var data = [];
    var d = {};
    d['value'] = items.price;
    d['maxVal'] = maxPrice;
    d['label'] = 'Price';
    data.push(d);

    d = {};
    d['value'] = '27';
    d['maxVal'] = '50';
    d['label'] = 'Age';
    data.push(d);

    d = {};
    d['value'] = items.rating;
    d['maxVal'] = '100';
    d['label'] = 'Rate';
    data.push(d);

    d = {};
    d['value'] = items.height;
    d['maxVal'] = '100';
    d['label'] = 'Height';
    data.push(d);
    
  return (
    <div className="bar-container">
        <h3 className="header-stats">Stats</h3>
        <ul className="bar-graph">
      {data.map(item => {
        const barHeight = (item.value / item.maxVal) * 100;
        const emptySpaceHeight = 100 - barHeight;

        return (
          <li key={item.label}>
            <ul className="gauge">
              <li className="meter" style={{ top: `${100 - barHeight}%` }} data-value={item.value}></li>
              {[...Array(16)].map((_, index) => (
                <li key={index}></li>
              ))}
              <li className="empty-space" style={{ top: `${100 - emptySpaceHeight}%` }}></li>
            </ul>
            <span>{item.label}</span>
          </li>
        );
      })}
    </ul>
    </div>
  );
};

export default BarGraph;

