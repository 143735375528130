import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';


import MyCarousel from "./Carousel";
import HeroSection from "./Hero";
import './PokemonBinder.css';
import './carousel.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const POKEMON_DATA = [
  { "id": "1", "name": "Alex", "description": "the best one", "image": "https://via.placeholder.com/150" }, { "id": "2", "name": "Dadda", "description": "the next best one", "image": "https://via.placeholder.com/150" }
  , { "id": "3", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }, { "id": "4", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }
  , { "id": "5", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }, { "id": "6", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }
  , { "id": "7", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }, { "id": "8", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }
  , { "id": "9", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }, { "id": "10", "name": "Pokemon name", "description": "description goes here", "image": "https://via.placeholder.com/150" }
];

function getInitialScreenSize() {
  const width = window.innerWidth;
  if (width < 768) {
    return 1; // Mobile screen
  } else if (width >= 768 && width < 992) {
    return 2; // Medium screen
  } else {
    return 3; // Large screen
  }
}



const PokemonBinder = ({ collection }) => {
  const navigate = useNavigate();

  const [numberofitems , setNumberofitems] = useState(0);
  const [totalcost , setTotalcost] = useState(0);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength).trim() + '...';
    }
    return text;
  };

  // Render the truncated header if the screen size is small
  const renderCardHeader = (header) => {
    if (screenSize === 1) { // Mobile screen
      return <div className="card-header">{truncateText(header, 5)}</div>;
    }
    else if (screenSize === 2) { // Mobile screen
      return <div className="card-header">{truncateText(header, 10)}</div>;
    }
    return <div className="card-header">{header}</div>;
  };

  const [orderby, setOrderby] = useState('name');
  const [sortOrder, setSortOrder] = useState("asc");


  const handleOrderBy = (key) => {
    if (key === orderby && sortOrder === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder("asc");
    }
    setOrderby(key);
  };


  const [children, setData] = useState([]);
  function fetchData() {
    const url = "images/collection_staged.json";
    axios.get(url)
      .then(response => {
        var jsonObj = []; var filteredObj = [];
        try {
          jsonObj = JSON.parse(response.data);
        }
        catch (error) {
          console.log('error1');
          jsonObj = response.data;
        }
        setNumberofitems(0);
        let total = 0; // Initialize total variable
        for (var item in jsonObj) {
          if (jsonObj[item].collection === collection) {
            filteredObj.push(jsonObj[item]);
            setNumberofitems(prevNumber => prevNumber + 1);
            total += Number(jsonObj[item]['price']); // Accumulate prices
          }
        }
        setTotalcost(total);

        filteredObj.sort((a, b) => {
          const valueA = a[orderby];
          const valueB = b[orderby];
        
          // Check if the values are integers
          const isValueAInteger = Number.isInteger(parseInt(valueA));
          const isValueBInteger = Number.isInteger(parseInt(valueB));
        
          if (isValueAInteger && isValueBInteger) {
            // Both values are integers
            return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
          } else if (isValueAInteger) {
            // Only valueA is an integer, so it should be sorted before valueB
            return sortOrder === "asc" ? -1 : 1;
          } else if (isValueBInteger) {
            // Only valueB is an integer, so it should be sorted before valueA
            return sortOrder === "asc" ? 1 : -1;
          } else {
            // None of the values are integers, perform a locale-based string comparison
            if (sortOrder === "asc") {
              return valueA.localeCompare(valueB);
            } else {
              return valueB.localeCompare(valueA);
            }
          }
        });
        
        


        setCurrentPage(0);
        setData(filteredObj);
      })
      .catch(error => {
        console.log('error2');
        console.log(error);

      });
  }

  useEffect(() => {
    fetchData();
  }, [collection, orderby, sortOrder, numberofitems, totalcost]);

  const [items, setItems] = useState([
    { id: '1', content: 'Item 1' },
    { id: '2', content: 'Item 2' },
    { id: '3', content: 'Item 3' },
    { id: '4', content: 'Item 4' },
    { id: '5', content: 'Item 5' },
    { id: '6', content: 'Item 6' },
  ]);

  const handleClick = (input1, input2) => {
    navigate('/' + input2 + '/' + input1);
  };


  // check current page
  const [currentPage, setCurrentPage] = useState(0);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const [screenSize, setScreenSize] = useState(getInitialScreenSize());

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width < 768) {
      setScreenSize(1); // Mobile screen
    } else if (width >= 768 && width < 992) {
      setScreenSize(2); // Medium screen
    } else {
      setScreenSize(3); // Large screen
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  console.log("screenSize:", screenSize);

  return (
    <>


      <Container className="pokemon-binder">
        {/* <BootstrapTabs /> */}
        {/* <MyCarousel /> */}
        <HeroSection />
        <MyCarousel
          show={screenSize}
          collection={collection}
        >
        </MyCarousel>
        <div className="page-wrapper">
          <div className="page">
            <h3>Order By:</h3>
            <Row>
              <Col md={4}>
                <div className="bar-container">
                  <Row >
                    <Col xs={6}>
                      <Form>
                        <Form.Group>
                          <Button className="button-padding" variant="primary" onClick={() => handleOrderBy('name')}>
                            Name
                          </Button>
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col xs={6}>
                      <Form>
                        <Form.Group controlId="formGroupExample">
                          <Button className="button-padding" variant="primary" onClick={() => handleOrderBy('price')}>
                            Price
                          </Button>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                  <Row >
                    <Col xs={6}>
                      <Form>
                        <Form.Group controlId="formGroupExample">
                          <Button className="button-padding" variant="primary" onClick={() => handleOrderBy('number')}>
                            Number
                          </Button>
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col xs={6}>
                      <Form>
                        <Form.Group controlId="formGroupExample">
                          <Button className="button-padding" variant="primary" onClick={() => handleOrderBy('rating')}>
                            Rating
                          </Button>
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col xs={6}>
                      <Form>
                        <Form.Group controlId="formGroupExample">
                          <Button className="button-padding" variant="primary" onClick={() => handleOrderBy('height')}>
                            Height
                          </Button>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={2}>
                <Button
                  variant="primary"
                  onClick={() => handleOrderBy(orderby)}
                  className="button-padding"
                >
                  {orderby.charAt(0).toUpperCase() + orderby.slice(1)}{" "}
                  {sortOrder === "asc" ? "▲" : "▼"}
                </Button>
              </Col>
              <Col md={4}>
              <div className="details-collection">
                  <Row >
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Name:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={collection} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Number of Items:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={numberofitems} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                  </Row>
                  <Row >
                    <Col md={6}>
                    <Form>
                      <Form.Group controlId="formGroupExample">
                        <Form.Label>Total Cost:</Form.Label>
                        <InputGroup>
                        <Form.Control type="text" readOnly value={totalcost} style={{ color: 'black' }} />
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {currentPage > 0 && (
                  <Button className="button-padding" variant="outline-secondary" onClick={handlePrevPage}>
                    Prev Page
                  </Button>
                )}
              </Col>
              <Col xs={6} className="text-end">
                {currentPage < children.length / 4 && (
                  <Button className="button-padding" variant="outline-secondary" onClick={handleNextPage}>
                    Next Page
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="card-section-wrapper">
              {children.slice(currentPage * 4, currentPage * 4 + 4).map(
                (pokemon) => (
                  <Col xs={3} key={collection + "/" + pokemon.name}>
                    <div className="card collection-item" key={collection + "/" + pokemon.name} onClick={() => handleClick(pokemon.name, pokemon.collection)}>
                    {renderCardHeader(pokemon.name)}
                      <div className={`card-body ${screenSize === 1 ? ' small-screen' : ''}`}>
                        <img className="image-small" src={"https://collectables.ashworthcloud.com/images/collection/" + collection + "/" + pokemon.name + ".jpg"} alt={pokemon.name} />
                        <p>{`${screenSize === 1 ? '£' + pokemon.price : pokemon.description}`}</p>
                      </div>
                    </div>
                  </Col>
                )
              )}
            </Row>
          </div>
          {/* <div className="page">
            {children.slice(
              currentPage * 4 + 4,
              currentPage * 4 + 4 + 4
            ).map((pokemon) => (
              <div className="card collection-item" key={pokemon.name} onClick={() => handleClick(pokemon.name, pokemon.collection)}>
                <div className="card-header">{pokemon.name}</div>
                <div className="card-body">
                  <img className="image-small" src={"https://collectables.ashworthcloud.com/images/collection/" + collection + "/" + pokemon.name + ".jpg"} alt={pokemon.name} />
                  <p>{pokemon.description}</p>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </Container>
    </>
  );
};

export default PokemonBinder;

