 
  export function init(setIsAuthenticated) {
    // check if authentication token exists in local storage
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      setIsAuthenticated(true);
    }
  
    // update UI based on authentication state
    // updateUI();
  }
  