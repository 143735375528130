import React from "react";
import "./Hero.css"; // Import CSS file for styling

function HeroSection() {
    return (
        <div className="hero-section">
            <div className="hero-container">
                <img
                    alt="My Hero Image"
                    src="images/hero.jpg"
                    className="hero-image"
                />
                <div className="hero-text">
                    <h1>Welcome to my personal collection showcase!</h1>
                    <p>This app is dedicated to displaying my beloved collections of vintage Star Wars toys, Funko Pops, Pokemon cards and more, as well as sharing memories of my son's growth through photos and information.</p>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
