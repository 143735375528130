import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, NavLink } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faPerson, faDragon, faCow, faStopwatch, faGhost, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Nav.css';


library.add(faUnlock);
library.add(faUser);
library.add(faPerson);
library.add(faDragon);
library.add(faCow);
library.add(faStopwatch);
library.add(faGhost);


// import { Router, Routes, Route, Link } from "react-router-dom";
// import { createBrowserHistory } from "history";

// import LoginPage from "./LoginPage";

// const history = createBrowserHistory();

const NavItem = styled(Nav.Link)`
  &:hover {
    color: #fff;
    background-image: url(${({ bgImg }) => bgImg});
    background-size: cover;
    background-position: center;
  }
`;

const NavDropdownItem = styled(NavDropdown.Item)`
  &:hover {
    color: #fff;
    background-image: url(${({ bgImg }) => bgImg});
    background-size: cover;
    background-position: center;
  }
`;

const data = [
  { id: 1, name: "John Doe", age: 25, email: "johndoe@example.com" },
  { id: 2, name: "Jane Smith", age: 30, email: "janesmith@example.com" },
  { id: 3, name: "Bob Johnson", age: 40, email: "bobjohnson@example.com" }
];

const columns = [
  { name: "ID", selector: (row, i) => row.id, sortable: true },
  { name: "Name", selector: (row, i) => row.name, sortable: true },
  { name: "Age", selector: (row, i) => row.age, sortable: true },
  { name: "Email", selector: (row, i) => row.email, sortable: true }
];

function NavMenu({ isAuthenticated, handleLogin, onNavClick }) {

  function handleClick(propValue) {
    
    console.log(propValue);
    onNavClick(propValue);
    setExpanded(false);
  }

  const handleToggle = (isExpanded) => {
    setExpanded(isExpanded);
  };

  const [expanded, setExpanded] = useState(false);

  
  return (
    <div className="d-flex justify-content-center">
      <Navbar  expanded={expanded} onToggle={handleToggle}  bg="light" expand="lg" className="w-40" >
        <Navbar.Brand href="/">
          <img className='logo-image'
            style={{ width: '64px' }}
            src="/images/home.jpg"
            alt="Home"
          />
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavItem href="#" onClick={() => handleClick('starwars')} className="nav-first flex-fill">
              <span className="nav-span"><FontAwesomeIcon icon="person" /></span>
              <span>Star Wars</span>
            </NavItem>
            <NavItem href="#" onClick={() => handleClick('pokemon')} className="nav-second flex-fill">
              <span className="nav-span"><FontAwesomeIcon icon="cow" /></span>
              <span>Pokemon</span>
            </NavItem>
            <NavItem href="#" onClick={() => handleClick('bulbasaur')} className="nav-third flex-fill">
              <span className="nav-span"><FontAwesomeIcon icon="dragon" /></span>
              <span>Bulbasaur</span>
            </NavItem>
            <NavItem href="#" onClick={() => handleClick('pocketwatches')} className="nav-fifth flex-fill">
              <span className="nav-span"><FontAwesomeIcon icon="stopwatch" /></span>
              <span>Pocket Watches</span>
            </NavItem>
            <NavItem href="#" onClick={() => handleClick('funko')} className="nav-first flex-fill">
              <span className="nav-span"><FontAwesomeIcon icon="ghost" /></span>
              <span>Funko Pops</span>
            </NavItem>
            <NavLink className="nav-fourth flex-fill" href="/admin" to="/admin">
            <span className="nav-span"><FontAwesomeIcon icon="unlock" /></span>
              Admin
            </NavLink>
            
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdownItem href="#" bgImg="/images/2.jpg">
                Dropdown Item 1
              </NavDropdownItem>
              <NavDropdown.Item href="#">Action</NavDropdown.Item>
              <NavDropdown.Item href="#">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#">Something else</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">Separated link</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavMenu;