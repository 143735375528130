import axios from 'axios';

export const auth = {
  authenticated: false,
  setAuth: function(authenticated) {
    this.authenticated = authenticated;
    // Perform any other actions needed when authentication state changes
  },
  getAuth: function() {
    return this.authenticated;
  }
};

export const refreshToken = async () => {
  
  const refreshToken = localStorage.getItem('refreshToken');
  const clientId = localStorage.getItem('clientId');
  const domainName = localStorage.getItem('domainName'); 
  const url = domainName + "/oauth2/token";
 
  const data = {
    'grant_type': 'refresh_token',
    'refresh_token': refreshToken,
    'client_id': clientId
  };
  

  try {  

    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
    });

    const { access_token, refresh_token } = response.data;
    // Store the new access token and refresh token in your app's state or local storage
    localStorage.setItem('jwtToken', access_token);
    // localStorage.setItem('refreshToken', refresh_token);

    return access_token;
  } catch (error) {
    console.log(error);
    // Handle the error, e.g. display an error message to the user or redirect to the login page
    // ...
  }
};

  