import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import configData from './config.json';
import axios from 'axios';
import { refreshToken } from './auth.js';



const FileUpload = (props) => {

  // const itemNameRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const { input1Value, input2Value, shouldPost, onModalClose } = props;
  // get data for modal
  const [data, setData] = useState({});

  // for modal state
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    onModalClose();
  };
  
  // for modal form for adding to db
  const [itemname, setItemname] = useState(input1Value);
  const [collection, setCollection] = useState(input2Value);
  const [desc, setItemdesc] = useState('');
  const [number, setItemnos] = useState('');
  const [price, setItemprice] = useState('');
  const [rating, setItemrating] = useState('');
  const [height, setItemheight] = useState('');
    


  // for file upload
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (shouldPost) {
      const jwtToken = localStorage.getItem('jwtToken');
      const geturl = "https://" + configData.apigatewayid + ".execute-api.eu-west-1.amazonaws.com/prod/querydb";
      axios.post(geturl, {
        'table': 'collectablesItems',
        'Name': 'name',
        'Value': input1Value,
        'Range': 'collection',
        'RangeValue': input2Value
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        },
      })
        .then(response => {

          let obj = response.data[0];

          console.log(obj);
          console.log(obj.name);
          setData(obj);
         
          obj.hasOwnProperty('description') ? setItemdesc(obj.description) : setItemdesc('');
          obj.hasOwnProperty('price') ? setItemprice(obj.price) : setItemprice('');
          obj.hasOwnProperty('number') ? setItemnos(obj.number) : setItemnos('');
          obj.hasOwnProperty('rating') ? setItemrating(obj.rating) : setItemrating('');
          obj.hasOwnProperty('height') ? setItemheight(obj.height) : setItemheight('');
          
          
          setShow(true);
          // document.getElementById("modalItemName").value = response.data[0].name;
          // if (itemNameRef.current) {
          //   itemNameRef.current.value = response.data[0].name;
          // }
        })
        .catch(error => {
          refreshToken();
          console.error(error);
        });
    }
    else {
      setShow(true);
    }
  }, []);


  const uploadFile = async (file, signedUrl) => {
    try {
      delete axios.defaults.headers.common["Authorization"];
      const response = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': 'image/jpeg',
        },
      })
        .then(response => {
          console.log('File uploaded successfully!', response);
        })
        .catch(error => {
          console.log('error on put: ', error);
        });

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const addRecord = async (url, data, jwtToken) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        },
      });
      console.log('Record added successfully!', response);
      setLoading(false);
    } catch (error) {
      console.error('Error adding record:', error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const jwtToken = localStorage.getItem('jwtToken');
    setLoading(true);

    // define post to DynamoDB using API Gateway
    const addurl = "https://" + configData.apigatewayid + ".execute-api.eu-west-1.amazonaws.com/prod/inserttodb";
    const postData = {
      'table': 'collectablesItems',
      'name': itemname,
      'description': desc,
      'number': number,
      'price': price,
      'collection': collection,
      'rating': rating,
      'height': height
    };

    if (file === null) {
      // file null so not uploading it
      addRecord(addurl, postData, jwtToken);
    }
    else {
      // get pre-signed url from API Gateway/Lambda
      const url = "https://" + configData.apigatewayid + ".execute-api.eu-west-1.amazonaws.com/prod/generatesigned";
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      };

      console.log(headers);
      const data = {
        'name': 'images/collection/' + collection + '/' + itemname + '.jpg',
        'bucket': 'collectables-prod'
      };
      
      const response = await axios.post(url, data, { headers })
        .then((response) => {
          console.log(response);
          console.log(response.data['result']);

          // upload file to s3 using pre-signed url
          const s3url = response.data['result'];
          uploadFile(file, s3url);

          // upload to DynamoDB
          addRecord(addurl, postData, jwtToken);
        })
        .catch((error) => {
          console.error(error);
          refreshToken();
        });
    }

  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Collection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label> Upload photo:</Form.Label>
                  <Form.Control type="file" onChange={(handleFileChange)} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label> Collection:</Form.Label>
                  <Form.Select defaultValue={data.collection} onChange={e => setCollection(e.target.value)}>
                    <option>Please select...</option>
                    <option value="bulbasaur">Bulbasaur</option>
                    <option value="funko">Funko Pop</option>
                    <option value="pocketwatches">Pocket Watch</option>
                    <option value="pokemon">Pokemon</option>
                    <option value="starwars">Star Wars</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label> Name:</Form.Label>
                  <Form.Control defaultValue={data.name} type="text" onChange={e => setItemname(e.target.value)} />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label> Description:</Form.Label>
                  <Form.Control defaultValue={data.description ? data.description : ''} type="text" onChange={e => setItemdesc(e.target.value)} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label> Number:</Form.Label>
                  <Form.Control defaultValue={data.number ? data.number : ''} type="text" onChange={e => setItemnos(e.target.value)} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label> Price (£):</Form.Label>
                  <Form.Control defaultValue={data.price ? data.price : ''} type="text" onChange={e => setItemprice(e.target.value)} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Rating:</Form.Label>
                  <Form.Control defaultValue={data.rating ? data.rating : ''} type="text" onChange={e => setItemrating(e.target.value)} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Height:</Form.Label>
                  <Form.Control defaultValue={data.height ? data.height : ''} type="text" onChange={e => setItemheight(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div style={{ marginTop: '1rem' }}>
                  {/* <Button type="submit" disabled={!file} variant="primary">Upload</Button> */}
                  <Button type="submit" variant="primary">Update</Button>
                </div>
              </Col>
              <Col md={6}>
                {loading && (
                  <div className="overlay">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
              </Col>
              
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default FileUpload;
