import React from 'react';
import { Link } from 'react-router-dom';
import PokemonBinder from "./PokemonBinder";

function Home({ collection }) { 

  return (
    <div>
        <Link to="/about">Go to About page</Link>
        <PokemonBinder collection={collection}/>

    </div>
  );
}

export default Home;
