import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ErrorPage from "./error-page";
import Home from './Home';
import About from './About';
import Admin from './Admin';
import NavMenu from "./Nav";
import Footer from "./Footer";
import Collection from "./Collection";
import { Routes, Route, useNavigate } from 'react-router-dom';

function App() {

  const navigate = useNavigate();

  const [collection, setCollection] = useState('starwars');

  function handleNavClick(propValue) {
    console.log('clicked');
    console.log(propValue);
    setCollection(propValue);
    // // if (window.location.pathname !== event.target.pathname) {
    if (window.location.pathname !== '/') {
      // Navigate to the link
      // window.location.href = '/';
      navigate('/');
    }
  }

  return (

    <>
      <div>
        <Helmet>
          <title>Collectables</title>
          <link rel="icon" type="image/png" href="images/favicon.jpg" sizes="16x16" />
          <meta name="description" content="collectable website for the ashworth family" />
        </Helmet>
      </div>
      <React.StrictMode>
        <NavMenu onNavClick={handleNavClick} />
        <Routes>
          <Route path="/" element={<Home collection={collection} />} />
          <Route path="/about" element={<About />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/:collectionName/:collectionId" element={<Collection />} />
        </Routes>
        <Footer />
      </React.StrictMode>
    </>
  );
}

export default App;