import React from 'react';
import "./Footer.css";
{/* <p>&copy; {new Date().getFullYear()} Your App Name</p> */}
function Footer() {
  return (
    <footer id="footer" className="dark">
     <div className="container">
        <div className="footer-widgets-wrap">
            <div className="row col-mb-50">
                <div className="col-lg-12">
                    <div className="row col-mb-50">
                        <div className="col-md-4">
                            <div className="widget clearfix">
                                <img style={{ width: '64px' }} src="/images/1.jpg" alt="Image" className="footer-logo"></img>
                                <div>
                                    <address>
                                        Sydenham
                                    </address>
                                    <abbr style={{ display: 'block' }} title="Phone Number"><strong>Phone: </strong></abbr>  TBC
                                    <abbr style={{ display: 'block' }} title="Email Address"><strong>Email:</strong></abbr> info@ashworthcloud.com
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="widget widget_links clearfix">
                                <h4>Other links</h4>
                                <ul>
                                    <li><a href="https://home.ashworthcloud.com/">Home Page</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="widget clearfix">
                                <h4>Recent Items</h4>
                                <div className="posts-sm row col-mb-30" id="post-list-footer">
                                    <div className="entry col-12">
                                        <div className="grid-inner row">
                                            <div className="col">
                                                <div className="entry-title">
                                                    <h4><a data-offset="10" href="/starwars/Darth Vader">Darth Vader</a></h4>
                                                </div>
                                                <div className="entry-meta">
                                                    <ul>
                                                        <li>12th January 2023</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="copyrights">
        <div className="container">
            <div className="row col-mb-30">
                <div className="col-md-6 text-center text-md-start">
                    Copyrights &copy; 2023 All Rights Reserved 2023
                </div>
                <div className="col-md-6 text-center text-md-end">
                    <div className="d-flex justify-content-center justify-content-md-end">
                        <a href="#" className="social-icon si-small si-borderless si-facebook">
                            <i className="icon-facebook"></i>
                            <i className="icon-facebook"></i>
                        </a>
                    </div>
                    <div className="clear"></div>
                    <i className="icon-envelope2"></i> info@ashworthcloud.com <span className="middot">&middot;</span> <i className="icon-headphones"></i> TBC
                </div>
            </div>
        </div>
    </div>
    </footer>
  );
}

export default Footer;
